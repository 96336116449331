@use "./variables" as *;

.slides > .navigation {
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  margin: 0 5rem 2rem;
  align-items: center;

  .next > div {
    justify-content: flex-end;
  }

  .previous,
  .next {
    flex: 1;

    & > div {
      outline: none;
      display: flex;
      cursor: pointer;
      font-weight: bold;
      line-height: 1.5rem;
      color: $border-color;
      font-size: $font-size * 1.3;

      &:hover {
        color: $font-color-link;
      }
    }
  }
}
