.post .members {
  .member {
    margin-right: 0.5rem;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }

    .avatar {
      width: 3rem;
      height: 3rem;
      min-width: 3rem;
      min-height: 3rem;
      border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}
