@font-face {
  font-weight: 400;
  font-family: "Source Sans Pro";
  src: url("/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf")
    format("truetype");
}

@font-face {
  font-weight: 600;
  font-family: "Source Sans Pro Semi Bold";
  src: url("/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf")
    format("truetype");
}

@font-face {
  font-weight: 300;
  font-family: "Roboto Slab Light";
  src: url("/fonts/Roboto_Slab/RobotoSlab-Light.ttf") format("truetype");
}
