@use "./variables" as *;

.post {
  flex: 1;
  display: flex;
  padding: 1rem;
  overflow: auto;
  flex-direction: column;
  box-shadow: #c9d3df 0px 1px 2px;

  .header {
    display: flex;

    .logo {
      width: 8rem;
      display: flex;

      .image {
        flex: 1;
        min-width: 128px;
        min-height: 128px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    .details {
      flex: 1;
      display: flex;
      margin-left: 1rem;
      justify-content: center;
      flex-direction: column;
      padding: 0.5rem 0.5rem 0.5rem 1rem;
      border-left: 1px solid $border-color;

      .description {
        font-size: 1.2rem;
        margin-top: 0.5rem;
        font-style: italic;
        font-family: "Roboto Slab Light";
      }
    }
  }

  .kpis {
    display: flex;
    margin: 2rem 0;
    justify-content: center;

    .kpi {
      flex: 1;
      margin: 1rem;
      min-width: 0;
      max-width: 33.333%;
      font-size: 1.2rem;
      padding: 1rem 2rem;
      text-align: center;
      line-height: 1.6rem;
      border: 1px solid $border-color;
      background-color: $background-color;

      .value,
      .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .value {
        margin: 0.4rem;
        font-size: 2rem;
        font-weight: bold;
        font-family: "Source Sans Pro Semi Bold";
      }
    }
  }

  .cards {
    flex: 1;
    display: flex;
    overflow: auto;

    .card {
      flex: 1;
      display: flex;
      overflow: auto;
      padding: 0rem 2rem;
      flex-direction: column;
      background-color: #ffffff;
      border-left: 1px solid $border-color;

      &:first-child {
        padding-left: 1rem;
        border-left: none;
      }

      &:last-child {
        padding-right: 1rem;
      }

      h3 {
        padding-bottom: 0.5rem;
        margin: 0 0 0.5rem 0rem;
        border-bottom: 1px solid $border-color;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .markdown-body {
        flex: 1;
        overflow: auto;
        padding-top: 0.5rem;
      }
    }
  }
}
