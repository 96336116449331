@use "sass:math";
@use "./variables" as *;

#__next > .header {
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: $base-size * 0.5;
  background-color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid $border-color;

  .marianne {
    width: 90px;
    height: 60px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(/images/logo-fabrique.svg);
  }

  h1 {
    flex: 1;
    line-height: $base-size * 1.8;
    margin: 0 0 0 $base-size * 0.5;

    a {
      color: $font-color;
    }

    .description {
      a {
        color: $font-color-link;
      }
    }
  }

  .menu {
    display: flex;
    margin-right: $base-size * 0.5;

    .profile {
      display: flex;
      text-align: right;
      flex-direction: column;
      justify-content: center;
      margin-right: $base-size * 0.5;

      .name {
        font-size: $font-size * 1.1;
        margin-bottom: math.div($base-size, 4);
      }

      .links {
        display: flex;
        a {
          display: flex;
          align-items: center;
          padding: 0 math.div($base-size, 4);
          font-size: math.div($font-size, 1.1);
          border-right: 1px solid darken($background-color, 10%);

          svg {
            margin-right: math.div($base-size, 4);
          }

          &:last-child {
            padding-right: 0;
            border-right: none;
          }

          &.logout {
            color: $font-color-important;
          }
        }
      }
    }

    .avatar {
      width: 2.2rem;
      height: 2.2rem;
      margin-top: 1px;
      min-width: 2.2rem;
      border-radius: 50%;
      min-height: 2.2rem;
      background-size: cover;
    }
  }
}

@media screen and (max-width: 640px) {
  .nav {
    .profile,
    .avatar,
    a.button {
      display: none;
    }
  }
}
