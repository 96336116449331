@use "./variables" as *;

#__next > .wrapper {
  flex: 1;
  background-color: black;

  .background {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(8px);
    position: absolute;
    background-size: cover;
    -webkit-filter: blur(8px);
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/images/marianne-bg.svg);
  }

  .intro {
    top: 0;
    left: 0;
    flex: 1;
    right: 0;
    bottom: 0;
    display: flex;
    position: absolute;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);

    &.hidden {
      display: none;
    }

    h1 {
      padding: 0;
      font-size: 9rem;
      margin-bottom: 0;
      line-height: 10rem;
      position: relative;
    }

    h1:after {
      content: attr(data-h1);
      top: 0;
      left: 0;
      right: 0;
      color: transparent;
      position: absolute;
      background: -webkit-repeating-linear-gradient(
        -45deg,
        #003189,
        #fff,
        #e2011c
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 400%;
      text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25);
      animation: animateTextBackground 10s ease-in-out infinite;
    }

    @keyframes animateTextBackground {
      0% {
        background-position: 0 0;
      }
      25% {
        background-position: 100% 0;
      }
      50% {
        background-position: 100% 100%;
      }
      75% {
        background-position: 0 100%;
      }
      100% {
        background-position: 0 0;
      }
    }

    h2 {
      margin-top: -24px;
      margin-left: -74px;
      position: relative;
      letter-spacing: 8px;
      color: $border-color;
    }

    button {
      outline: none;
      font-size: 2em;
      cursor: pointer;
      margin-top: 40px;
      border-radius: 2px;
      color: $border-color;
      transition: all 0.5s ease;
      background-color: $base-color;
      border: 1px solid $border-color;
      padding: $base-size $base-size * 2;

      &:hover,
      &:focus {
        outline: none;
        color: $base-color;
        border: 1px solid $font-color-link;
        background-color: $font-color-link;
      }

      &:active {
        outline: none;
        color: $base-color;
        border: 1px solid darken($font-color-link, 10%);
        background-color: darken($font-color-link, 10%);
      }
    }
  }
}
