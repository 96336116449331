.extra {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    margin: 0 3em;
    font-size: 1.5em;
  }
}

img[alt="other-startup-logo"] {
  top: 20px;
  max-width: 64px;
  position: relative;
  display: inline-block;
}
