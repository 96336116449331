.missing {
  flex: 1;
  display: flex;
  color: #54677a;
  align-items: center;
  justify-content: center;

  ul {
    margin: 0 3em;
    font-size: 1.5em;
  }
}
